import React, { useContext, useEffect } from "react";
import Carousel from "../components/common/Carousel";
import About from "../components/home/About";
import FascinatingContainer from "../components/home/FascinatingContainer";
import Search from "../components/home/Search";
import CardContainer from "../components/home/CardContainer";
import { PlanContext } from "../contexts/PlanContextProvider";
import { useState } from "react";
import { getPopularPlan } from "../api/plan";

function Home() {
  const [popular, setPopular] = useState({popularPlans: [], representArea: []});

  const { popularPlans , representArea  } = popular


  useEffect(() => {
    const fetchPopular = async () => {
      try {
        const res = await getPopularPlan();
        setPopular(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPopular();
  }, []);

  return (
    <div>
      <About />
      <Search />
      <CardContainer header="เส้นทางที่ได้รับความนิยม">
        <Carousel popularPlans={popularPlans} representArea={representArea} />
      </CardContainer>
      <CardContainer header="สถานที่น่าสนใจ">
        <FascinatingContainer />
      </CardContainer>
    </div>
  );
}

export default Home;
