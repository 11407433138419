import axios from "axios";
import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import validator from "validator";
import { AuthContext } from "../../contexts/AuthContextProvider";
import { validateRegister } from "../../services/validate";

function RegisterForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");

  const [err, setError] = useState({});
  const [apiError, setApiError] = useState("");

  const navigate = useNavigate();

  const { register } = useContext(AuthContext);

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const errResult = validateRegister({
      email,
      fname,
      lname,
      password,
      confirmPassword,
    });

    setError(errResult);

    if (Object.keys(errResult).length === 0) {
      try {
        const res = await axios.post("/user/register", {
          email,
          fname,
          lname,
          password,
          confirmPassword,
        });
        navigate("/login");
      } catch (err) {
        console.log(err);
        setApiError(err.response.data.message);
      }
    }
  };

  return (
    <form onSubmit={(e) => handleSubmitForm(e)}>
      <div className="d-flex flex-column mb-3 mt-3 fw-light">
        <label htmlFor="">อีเมล</label>
        <input
          type="text "
          placeholder="email@gmail.com"
          className={`form-control mt-1 fw-light shadow-none ${
            err.email ? "is-invalid" : ""
          }`}
          onChange={(e) => setEmail(e.target.value)}
        />
        {err.email && <div className="invalid-feedback">{err.email}</div>}
      </div>

      <div className="d-flex flex-column mb-3 mt-3 fw-light">
        <label htmlFor="">ชื่อ</label>
        <input
          type="text "
          className={`form-control mt-1 fw-light shadow-none ${
            err.fname ? "is-invalid" : ""
          }`}
          onChange={(e) => setFname(e.target.value)}
        />
        {err.fname && <div className="invalid-feedback">{err.fname}</div>}
      </div>

      <div className="d-flex flex-column mb-3 mt-3 fw-light">
        <label htmlFor="">นามสกุล</label>
        <input
          type="text "
          className={`form-control mt-1 fw-light shadow-none ${
            err.lname ? "is-invalid" : ""
          }`}
          onChange={(e) => setLname(e.target.value)}
        />
        {err.lname && <div className="invalid-feedback">{err.lname}</div>}
      </div>

      <div className="d-flex flex-column mb-3 mt-3 fw-light " required>
        <label htmlFor="">รหัสผ่าน</label>
        <input
          type="text "
          className={`form-control mt-1 fw-light shadow-none ${
            err.password ? "is-invalid" : ""
          }`}
          onChange={(e) => setPassword(e.target.value)}
        />
        {err.password && <div className="invalid-feedback">{err.password}</div>}
      </div>
      <div className="d-flex flex-column mb-3 mt-3 fw-light ">
        <label htmlFor="">ยืนยันรหัสผ่าน</label>
        <input
          type="text "
          className={`form-control mt-1 fw-light shadow-none ${
            err.confirmPassword ? "is-invalid" : ""
          }`}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {err.confirmPassword && (
          <div className="invalid-feedback">{err.confirmPassword}</div>
        )}
      </div>

      <div className="d-grid gap-3 my-4">
        <button className="btn btn-secondary text-white fw-light" type="submit">
          สมัครสมาชิก
        </button>
      </div>
      {apiError && (
        <div className="alert alert-danger" role="alert">
          {apiError}
        </div>
      )}
    </form>
  );
}

export default RegisterForm;
