import React, { useState, useRef } from "react";

function ImageUpload({ value, onChange, onDelete }) {
  const inputEl = useRef();


  return (
    <>
      <div
        className="position-relative"
        role="button"
        onClick={() => inputEl.current.click()}
      >
        {value  ? (
          <>
            <button
              className="btn-close position-absolute"
              style={{ top: 10, right: 25 }}
              onClick={(e) => {
                e.stopPropagation();
                inputEl.current.value = "";
                onDelete();
              }}
            />
            {/* อยากใส่รูปเก่าเข้าไป แล้วสามารถปิดได้??? */}
            <img
              src={
                URL.createObjectURL(value)
              }
              alt="post"
              className="img-fluid"
            />
          </>
        ) : (
          <div className="drop-zone border rounded  d-flex flex-column align-items-center justify-content-center mt-4">
            <i className="fas fa-images fs-1 text-muted "></i>
            <div className="fw-light mt-2">
              เพิ่มรูปภาพหน้าปก(png, jpeg) ขนาดไม่เกิน 2MB
            </div>
            <input type="file" className="d-none" />
          </div>
        )}
      </div>
      <input type="file" className="d-none" ref={inputEl} onChange={onChange} />
    </>
  );
}

export default ImageUpload;
