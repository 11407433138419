import React from "react";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { PlanContext } from "../../contexts/PlanContextProvider";
import SearchInput from "./SearchInput";

function SearchForm() {
  const { searchPlan } = useContext(PlanContext);

  const [place, setPlace] = useState("");
  const [dayNum, setDayNum] = useState("1");

  const handleOnSubmit = (e) => {
    e.preventDefault();
    searchPlan(place, dayNum);
  };

  return (
    <form onSubmit={(e) => handleOnSubmit(e)}>
      <div className="row bg-white shadow-sm bg-white mx-5 px-1   align-items-center px-lg-2 border-ligth rounded-2 border">
        <div className="col-12 pt-3 pb-1 px-2 col-lg-7 py-lg-2">
          <SearchInput
            onChange={setPlace}
            mode="place-input"
            placeholder="เลือกสถานที่ เช่น ประเทศ รัฐ จังหวัด"
          />
        </div>
        <div className="col-12  pb-2 px-2 col-lg-3 py-lg-2">
          <SearchInput
            onChange={setDayNum}
            mode=""
            placeholder="จำนวนวันที่ไป"
          />
        </div>
        <div className="col-12 d-grid pb-3 px-2 col-lg-2 py-lg-2">
          <button
            type="submit"
            className="btn btn-primary btn-lg rounded fw-light"
          >
            ค้นหา
          </button>
        </div>
      </div>
    </form>
  );
}

export default SearchForm;
