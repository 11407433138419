import React from "react";
import formatDate from "../../../services/dateFormat";

function HorizontalCardHeader(props) {
  const { update } = props;

  const dateFormat = new Date(update);

  const date = formatDate(dateFormat);

  return (
    <div className="col-12 px-3">
      <h5 className="card-title fw-light ">
        {props.dest}{" "}
        <span className="fs-smaller ms-2 text-muted">
          อัพเดท <span>{date}</span>
        </span>
      </h5>
    </div>
  );
}

export default HorizontalCardHeader;
