import React from "react";

function PlaceMark({ place, lat, lng }) {
  
  return (
    <a
      href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`}
      className="my-2"
      style={{ display: "block" }}
      target = "_blank"
    >
      <i className="fas fa-map-marker-alt text-danger fs-4 me-3" />

      {place.placeName}
    </a>
  );
}

export default PlaceMark;
