import { createContext, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../config/axios";
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from "../services/localStorage";

const AuthContext = createContext();

function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMe = async () => {
      try {
        const token = getAccessToken();
        
        if (token) {
          const resMe = await axios.get("/my-user/me");
          setUser(resMe.data.user);
        }
      } catch (err) {
        removeAccessToken();
        navigate("login");
      }
    };
    fetchMe();
  }, []);

  const register = async (input) => {
    try {
      await axios.post("/user/register", input);
     
    } catch (err) {
      console.log(err);
    }
  };

  const login = async (email, password) => {
      const res = await axios.post("/user/login", { email, password });
     
    setAccessToken(res.data.token);
    const resMe = await axios.get("/my-user/me");
    setUser(resMe.data.user);
  };

  const logout = () => {
    removeAccessToken();
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ register, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;

export { AuthContext };
