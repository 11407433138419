import React from "react";

function Button(props) {
  const { style, content, icon, onClick } = props;

  let value;

  switch (content) {
    case "บันทึก":
      value = false;
      break;
    case "เผยแพร่":
      value = true;
      break;
    default:
      value = "";
  }

  return (
    <button
      className={`btn btn-${style} fw-light px-2 px-sm-4 d-lg-flex align-items-lg-center px-lg-3 shadow-none`}
      value={value}
      onClick={(e) => onClick(e.target.value)}
    >
      {icon && <i className={`${icon} me-2 fs-5`} />}
      {content}
    </button>
  );
}

export default Button;
