import React from "react";
import { Link } from "react-router-dom";

function LoginButton(props) {
  const { style, content, icon } = props;

  return (
    <Link
    to = "/login"
      className={`btn btn-${style} fw-light px-2 px-sm-4 d-lg-flex align-items-lg-center px-lg-3 shadow-none`}
      type = "button"
    >
      {icon && <i className={`${icon} me-2 fs-5`} />}
      {content}
    </Link>
  );
}

export default LoginButton;
