import React from "react";
import { AuthContext } from "../../contexts/AuthContextProvider";
import { useContext, useState } from "react";
import { ErrorContext } from "../../contexts/ErrorContextProvider";
import { useNavigate } from "react-router-dom";
import { validateLogin } from "../../services/validate";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { login } = useContext(AuthContext);

  const [err, setError] = useState({});
  const [apiError, setApiError] = useState('')

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    const errResult = validateLogin({
      email,
      password,
    });

    setError(errResult);
    

    // แก้บัค
    if (Object.keys(errResult).length === 0) {
      try {
        await login(email, password);
        setError({});
        setEmail("");
        setPassword("");
        navigate("home");
      } catch (err) {
        setApiError(err.response.data.message);
      }
    }
  };

  return (
    <form action="" onSubmit={(e) => handleSubmitLogin(e)}>
      <div className="d-flex flex-column mb-3 mt-3 fw-light">
        <label htmlFor="">อีเมล</label>
        <input
          type="text "
          placeholder="email@gmail.com"
          className={`form-control mt-1 fw-light shadow-none ${
            err.email ? "is-invalid" : ""
          }`}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        {err.email && <div className="invalid-feedback">{err.email}</div>}
      </div>
      <div className="d-flex flex-column mb-3 mt-3 fw-light ">
        <label htmlFor="">รหัสผ่าน</label>
        <input
          type="password "
          className={`form-control mt-1 fw-light shadow-none ${
            err.password ? "is-invalid" : ""
          }`}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        {err.password && <div className="invalid-feedback">{err.password}</div>}
      </div>
      <div className="text-end text-secondary">
        <small>ลืมรหัสผ่าน</small>
      </div>

      <div className="d-grid gap-3 my-3">
        <button type="submit" className="btn btn-secondary text-white fw-light">
          เข้าสู่ระบบ
        </button>
      </div>
      {apiError && (
        <div className="alert alert-danger" role="alert">
          {apiError}
        </div>
      )}
    </form>
  );
}

export default LoginForm;
