import React, { useEffect, useState } from "react";
import PostDetailCard from "../components/post-details/PostDetailCard";
import avarta from "../img/avarta/man.jpg";
import Button from "../components/common/Button";
import HorizontalCardProfile from "../components/common/horizontal-card/HorizontalCardProfile";
import {
  createSavePlan,
  deleteSavePlan,
  getOnePlan,
  getSavePlan,
} from "../api/plan";
import { useParams } from "react-router-dom";
import VoteButton from "../components/common/VoteButton";
import SaveButton from "../components/common/SaveButton";
import ShareButton from "../components/common/ShareButton";
import { createVotePlan, deleteVotePlan, getVotePlan } from "../api/plan";

// import Modal from "../components/common/Modal";

function PlanDetails() {
  const [plan, setPlan] = useState({});
  const [vote, setVote] = useState({});
  const [save, setSave] = useState({});
  // const [open, setOpen] = useState(false);

  const { planId } = useParams();

  // โหลด plan vote save เก็บไว้ใน state
  useEffect(() => {
    const addPlan = async (id) => {
      const res = await getOnePlan(id);
      setPlan(res.data.plan);
    };

    const getVote = async (id) => {
      const res = await getVotePlan(id);
      const vote = res.data;
      setVote(vote);
    };

    const getSave = async (id) => {
      const res = await getSavePlan(id);
      const save = res.data;
      setSave(save);
    };

    try {
      addPlan(planId);
      getVote(planId);
      getSave(planId);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const { planName, Places, User = { fname: "", lname: "" } } = plan;

  // ทำการแยกสถานที่ของแต่ละวัน
  let placesByDayObj;
  let placesByDay = [];
  

  if (Places !== undefined) {
    placesByDayObj = Places.reduce((acc, curr) => {
      if (acc[curr.dayId] === undefined) {
        const newCurr = [];
        newCurr.push(curr);
        acc[curr.dayId] = newCurr;
      } else {
        acc[curr.dayId].push(curr);
      }
      return acc;
    }, {});
    placesByDay = Object.entries(placesByDayObj);
  }

  const handleVote = () => {
    const createVote = async () => {
      const res = await createVotePlan(planId);
      const votePlan = res.data;
      setVote(votePlan);
    };

    const deleteVote = async () => {
      await deleteVotePlan(planId);
      setVote({});
    };
    try {
      if (Object.keys(vote).length !== 0) {
        deleteVote();
      } else {
        createVote();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = () => {
    const createSave = async () => {
      const res = await createSavePlan(planId);
      const savePlan = res.data;
      setSave(savePlan);
    };

    const deleteSave = async () => {
      await deleteSavePlan(planId);
      setSave({});
    };

    try {
      if (Object.keys(save).length !== 0) {
        deleteSave();
      } else {
        createSave();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const closeModal = () => {
  //   setOpen(false);
  // };

  return (
    <div>
      <div className="about-root-container">
        <div className="row shadow-sm bg-white mx-1  my-5 mx-sm-0  rounded-3  px-lg-3 py-4 justify-content-center">
          <h2 className="pb-3 pt-4 ps-4">
            เส้นทาง <span className="text-secondary">{planName}</span>
          </h2>
          <div className="row  justify-content-center  ">
            <div className="col-12 fs-small fw-light col-lg-5 ">
              <HorizontalCardProfile avarta={avarta} user={User} />
            </div>
            <div className="row col-12 mt-3 justify-content-center col-lg-7 mt-lg-0">
              <div className=" d-flex justify-content-between justify-content-lg-end gap-lg-3 ">
                <VoteButton onClick={() => handleVote()} vote={vote} />
                <SaveButton onClick={() => handleSave()} save={save} />
                <ShareButton />
              </div>
            </div>
          </div>
          <div className="row py-4">
            {placesByDay.map((el, idx) => (
              <PostDetailCard
                key={el[0]}
                id={el[0]}
                value={el[1]}
                day={idx + 1}
              />
            ))}
          </div>
          <div className="d-flex justify-content-end justify-content-lg-end gap-3 me-5">
            <Button style="outline-primary" content="กลับ" />
            <Button style="primary" content="กลับด้านบน" />
          </div>
        </div>
      </div>
      {/* <Modal>
        <h1>Test</h1>
      </Modal> */}
    </div>
  );
}

export default PlanDetails;
