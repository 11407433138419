import "./App.css";
import PrivateRoutes from "./private-route/PrivateRoutes";


function App() {
  return (
    <div className="App">
      
        <PrivateRoutes />
      
    </div>
  );
}

export default App;
