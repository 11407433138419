import React from "react";
import Accordion from "./Accordion";
import InfoBlog from "./InfoBlog";

function PostDetailCard({ id, value, day }) {
  return (
    <div>
      <div className="card  shadow-sm my-2" style={{ maxWidth: "100%" }}>
        <Accordion id={id} value={value}  day = {day}/>
      </div>
    </div>
  );
}

export default PostDetailCard;
