import PostCard from "../components/search-result/PostCard";
import SearchResultFilter from "../components/search-result/SearchResultFilter";
import Pagination from "../components/common/Pagination";
import HorizontalCard from "../components/common/HorizontalCard";
import { PlanContext } from "../contexts/PlanContextProvider";
import { useContext, useState } from "react";
import filterSearch from '../function/filterSearch'

function SearchResult() {
  const { searchResult } = useContext(PlanContext);
  const { place, plans } = searchResult;

  const [popular, setPopular] = useState(null);
  const [recent, setRecent] = useState(null);
  const [author, setAuthor] = useState("");
  const [route, setRoute] = useState("");

  const [page, setPage] = useState(1);

  const pageLimit = 10;

  const pageNum = plans.length / pageLimit;

  const limitPages = plans.slice((page - 1) * pageLimit, page * pageLimit);

  let filterPlans = filterSearch(limitPages, popular, recent, author, route);



  return (
    <div className="about-root-container">
      <div className="row shadow-sm bg-white mx-2 my-5 mx-sm-0  rounded-3  px-1 px-lg-3 py-4">
        <h2 className="pb-3 pt-4 ps-3">
          เส้นทางเที่ยวใน <span className="text-secondary">{place}</span>
        </h2>
        <SearchResultFilter
          mode=""
          setPopular={setPopular}
          popular={popular}
          setRecent={setRecent}
          recent={recent}
          setAuthor={setAuthor}
          author={author}
          setRoute={setRoute}
          route={route}
        />
        {filterPlans.map((plan) => (
          <HorizontalCard
            key={plan.id}
            planId={plan.id}
            user={plan.User}
            planName={plan.planName}
            planImage={plan.planImage}
            votes={plan.votes}
            places={plan.Places}
            represent={place}
            update={plan.updatedAt}
          />
        ))}
        <Pagination page={page} setPage={setPage} pageNum={pageNum} />
      </div>
    </div>
  );
}

export default SearchResult;
