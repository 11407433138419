const filterSearch = (plans, popular, recent, author, route) => {
  let filterPlans = [];
  

  if (Object.keys(plans).length !== 0) {
    if (popular || recent) {
      const plansArray = [...plans];
      filterPlans = plansArray.sort(
        (a, b) =>
          b.votes - a.votes || new Date(b.updatedAt) - new Date(a.updatedAt)
      );
    } else {
      const plansArray = [...plans];
      filterPlans = plansArray.sort(
        (a, b) =>
          a.votes - b.votes || new Date(a.updatedAt) - new Date(b.updatedAt)
      );
    }

    if (author || route) {
      filterPlans = filterPlans.filter(
        (el) =>
          (!route ||
            el.planName
              .trim()
              .toLowerCase()
              .includes(route.trim().toLowerCase())) &&
          (!author ||
            el.User.fname
              .trim()
              .toLowerCase()
              .includes(author.trim().toLowerCase()) ||
            el.User.lname
              .trim()
              .toLowerCase()
              .includes(author.trim().toLowerCase()))
      );
    }
  }

  return filterPlans;
};

export default filterSearch;
