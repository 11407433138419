import React from "react";

function FilterNav() {
  return (
    <div>
      {/* mobile */}
      <nav className="navbar navbar-expand-lg bg-white d-lg-none ">
        <div className="container-fluid">

            
          <span>ตัวกรอง</span>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-sort"></i>
          </button>

          <div
            className="d-lg-none collapse navbar-collapse bg-white px-3 py-3 mt-3 rounded-2 border"
            id="navbarScroll"
          >
            <form action="" className="d-lg-none">
              <div className="">
                <select name="" id="" className="form-select fw-light my-4">
                  <option value="">ความนิยมสูงสุด</option>
                </select>
                <select name="" id="" className="form-select fw-light my-4">
                  <option value="">อัพเดทล่าสุด</option>
                </select>

                <input
                  type="text"
                  placeholder="ชื่อผู้เขียน"
                  className="form-control fw-light my-4"
                />
                <input
                  type="text"
                  placeholder="จังหวัด"
                  className="form-control fw-light my-4"
                />
                <input
                  type="text"
                  placeholder="ชื่อเส้นทาง"
                  className="form-control fw-light my-4"
                />
              </div>
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default FilterNav;
