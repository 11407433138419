import React from "react";
import img1 from "../../img/mockup-img/asian-woman-wearing-japanese-traditional-kimono-walking-autumn-park.jpg";
import avarta from "../../img/avarta/man.jpg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContextProvider";

function CardRouteDesktop(props) {
  let navigate = useNavigate();
  const representArea = props.area;
  const { user } = useContext(AuthContext);

  const {
    id,
    planName,
    planImage,
    votes,
    User: { fname, profileImage },
  } = props.info;

  

  let slicedPlanName = planName;

  if (planName.length > 25) {
    slicedPlanName = planName.slice(0, 25) + "...";
  }

  return (
    <div>
      <a onClick={() => navigate(`/plan-details/${id}`)}>
        <div className="card ">
          <div className="card-body " style={{ minWidth: "280px" }}>
            <h5 className="card-title fw-normal mb-2">{slicedPlanName}</h5>
            <h6 className="card-subtitle text-muted fw-light fs-small">
              {representArea ? representArea: <br/>}
            </h6>
          </div>
          <div>
            <img src={planImage} width="100%" height="160px" alt="" />
          </div>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-8 fs-6">
                {" "}
                <img
                  src={`${profileImage || 'https://res.cloudinary.com/daqhiutvb/image/upload/v1655635212/ck01dobuwoh4bmo7wff5.jpg'} `}
                  className="rounded-circle me-3"
                  height="40px"
                  width="40px"
                />
                {fname}
              </div>
              <div className="col-4 text-end d-flex align-items-center ">
                <i className="fas fa-heart me-2 text-danger fs-4"></i> {votes}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default CardRouteDesktop;
