import CardRouteMobile from "../home/CardRouteMobile";
import CardRouteDesktop from "../home/CardRouteDesktop";
import { useEffect, useState } from "react";

function Carousel(props) {
  const { popularPlans, representArea } = props;
  const firstPopularPlans = popularPlans.slice(0, 4);
  const secondPopularPlans = popularPlans.slice(4,8)
  let cardRoute1 = (
    <div className="row mx-auto ">
      {firstPopularPlans.map((el, idx) => {
        switch (idx) {
          case 0:
            return (
              <div className="col-12 col-md-6 d-flex col-lg-4 col-xl-3" key={el.id}>
                <CardRouteDesktop
                  
                  info={el}
                  area={representArea[0]?.areaLevel2}
                />
              </div>
            );
          case 1:
            return (
              <div className="d-none d-md-flex col-md-6  col-lg-4 col-xl-3" key={el.id}>
                <CardRouteDesktop
                  
                  info={el}
                  area={representArea[1]?.areaLevel2}
                />
              </div>
            );
          default:
            return (
              <div className="d-none d-lg-flex col-lg-4 col-xl-3"  key={el.id}>
                <CardRouteDesktop
                 
                  info={el}
                  area={representArea[idx]?.areaLevel2}
                />
              </div>
            );
        }
      })}
    </div>
  );

  let cardRoute2 = (
    <div className="row mx-auto ">
      {secondPopularPlans.map((el, idx) => {
        switch (idx) {
          case 0:
            return (
              <div className="col-12 col-md-6 d-flex col-lg-4 col-xl-3"  key={el.id}>
                <CardRouteDesktop
                  
                  info={el}
                  area={representArea[0]?.areaLevel2}
                />
              </div>
            );
          case 1:
            return (
              <div className="d-none d-md-flex col-md-6  col-lg-4 col-xl-3"  key={el.id}>
                <CardRouteDesktop
                 
                  info={el}
                  area={representArea[1]?.areaLevel2}
                />
              </div>
            );
          default:
            return (
              <div className="d-none d-lg-flex col-lg-4 col-xl-3"  key={el.id}>
                <CardRouteDesktop
                  
                  info={el}
                  area={representArea[idx]?.areaLevel2}
                />
              </div>
            );
        }
      })}
    </div>
  );

  return (
    <div>
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active ">{cardRoute1}</div>
          <div className="carousel-item ">{cardRoute2}</div>
        </div>

        {/* carouselbutton */}

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
