import { useState, useContext, useEffect } from "react";
import ImageUpload from "../components/add-plan/ImageUpload";
import AddDayBlog from "../components/add-day/AddDayBlog";
import Button from "../components/common/Button";
import AddPlanContextProvider, {
  AddPlanContext,
} from "../contexts/AddPlanContextProvider";
import { PlanContext } from "../contexts/PlanContextProvider";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { getUpdatePlan } from "../api/plan";
import Spinner from "../components/common/Spinner";

function AddPlan() {
  const {
    day,
    setDay,
    planName,
    setPlanName,
    image,
    setImage,
    setIsActive,
    setIsUpdate,
    setUpdateId,
    loading,
  } = useContext(AddPlanContext);

  // คลิกแล้วเซ็ตค่า value ให้มันจัดการที่ AddPlanCOntext สำหรับการเพิ่ม plan
  const handleOnClick = (value) => {
    setIsActive(value);
  };

  // สำหรับการ update plan
  const handleUpdate = (value) => {
    setIsUpdate(value);
  };

  // get plan to be updated
  const [targetPlan, setTargetPlan] = useState({});

  // ถ้าเป็นการ update path จะมีค่านี้
  const { planId } = useParams();

  useEffect(() => {
    try {
      // ถ้ามี planId จะหมายถึงการอัพเดท จะไปดึงข้อมูลจากฐานข้อมูล
      if (planId !== undefined) {
        const getUpdate = async (planId) => {
          const res = await getUpdatePlan(planId);
          setPlanName(res.data.targetPlan.planName);
          setTargetPlan(res.data.targetPlan);
        };

        getUpdate(planId);
        setUpdateId(planId);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  // พอได้ targetPlan แล้ว เอาไป map day ใหม่
  useEffect(() => {
    if (Object.keys(targetPlan).length !== 0) {
      const { Places } = targetPlan;
      const allDayId = Places.map((el) => el.dayId);
      const uniqueDayId = Array.from(new Set(allDayId));
      setDay(uniqueDayId);
    }
  }, [targetPlan]);

  let isUpdatePlan = false;

  if (Object.keys(targetPlan).length !== 0) {
    isUpdatePlan = true;
  }

  return (
    <>
      {loading && <Spinner />}
      <div>
        <div className="about-root-container">
          <div className="row shadow-sm bg-white mx-1  my-5 mx-sm-0  rounded-3  px-lg-5 py-4 justify-content-center px-1">
            <h2 className="pb-3 pt-4 ps-4 ">
              เพิ่มเส้นทาง <span className="text-secondary">ของฉัน</span>
            </h2>

            <ImageUpload
              value={image}
              onChange={(e) => {
                if (e.target.files[0]) {
                  setImage(e.target.files[0]);
                }
              }}
              onDelete={() => setImage(null)}
            />

            <div>
              <div className="row my-4 ">
                <div className="col-12 d-flex flex-column col-lg-6">
                  <label htmlFor="" className="form-label">
                    ชื่อเส้นทาง
                  </label>
                  <input
                    type="text "
                    className="form-control"
                    onChange={(e) => setPlanName(e.target.value)}
                    value={planName}
                  />
                </div>
              </div>
              {day.map((el, idx) => (
                // แทรกตัวล่าสุดไม่ได้เพราะว่ามันตาม index -> อาจจะต้อง prop dayId ไปเพื่อ filter แทน และจะต้องเก็บ dayId ใน plan ด้วย
                <AddDayBlog
                  key={el}
                  day={idx + 1}
                  dayId={el}
                  targetPlan={targetPlan}
                />
              ))}

              <div className="d-grid gap-3 mt-4 d-lg-flex justify-content-lg-end mt-lg-5 mb-lg-3">
                {/* <Button style="outline-primary" content="ดูตัวอย่าง" /> */}
                <Button
                  style="outline-primary"
                  content="บันทึก"
                  onClick={planId === undefined ? handleOnClick : handleUpdate}
                />
                <Button
                  style="primary"
                  content="เผยแพร่"
                  onClick={planId === undefined ? handleOnClick : handleUpdate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPlan;
