import axios from "../config/axios";

const getPopularPlan = () => axios.get("/plans/popular");

const getSearchPlan = (placeName, dayNum) =>
  axios.get(`/plans/search-result?destination=${placeName}&days=${dayNum}`);

const getOnePlan = (planId) => axios.get(`/plans/${planId}`);

const createPlan = (planName, places, image, isActive) => {
  const formData = new FormData();
  formData.append("planName", planName);
  formData.append("places", JSON.stringify(places));
  formData.append("image", image);
  formData.append("isActive", isActive);
  return axios.post("/my-plans/newplan", formData);
};

const updatePlan = (planName, places, image, isUpdate, updateId) => {
  console.log(places);
  const formData = new FormData();
  formData.append("planName", planName);
  formData.append("places", JSON.stringify(places));
  formData.append("image", image);
  formData.append("isActive", isUpdate);
  return axios.patch(`/my-plans/${updateId}/update`, formData);
};

const getUpdatePlan = (planId) => axios.get(`my-plans/${planId}/update`);

const getMyPlans = () => axios.get("/my-plans");
const getMySavedPlans = () => axios.get('/my-plans/save');

const getVotePlan = (planId) => axios.get(`/plans/${planId}/vote`);
const createVotePlan = (planId) => axios.post("/plans/vote", { planId });
const deleteVotePlan = (planId) => axios.delete(`plans/${planId}/vote`);

const getSavePlan = (planId) => axios.get(`/plans/${planId}/save`);
const createSavePlan = (planId) => axios.post("/plans/save", { planId });
const deleteSavePlan = (planId) => axios.delete(`plans/${planId}/save`);

export {
  getPopularPlan,
  getSearchPlan,
  
  getOnePlan,
  createPlan,
  getUpdatePlan,
  getMyPlans,
  getMySavedPlans,
  updatePlan,
  createVotePlan,
  deleteVotePlan,
  getVotePlan,
  getSavePlan,
  createSavePlan,
  deleteSavePlan
};
