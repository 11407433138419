import Header from "../pages/Header";
import Footer from "../pages/Footer";
import { Outlet } from "react-router-dom";
import PlanContextProvider from "../contexts/PlanContextProvider";
import AddPlanContextProvider, {
  AddPlanContext,
} from "../contexts/AddPlanContextProvider";

function Layout() {
  return (
    <>
      <div className="container-fluid">
        <Header />
      </div>
      <div className="container body-container " style={{ marginTop: "65px" }}>
        <PlanContextProvider>
          <AddPlanContextProvider>
            <Outlet />
          </AddPlanContextProvider>
        </PlanContextProvider>
      </div>

      <div className="container-fluid">
        <Footer />
      </div>
    </>
  );
}

export default Layout;
