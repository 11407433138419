import avatar from "../../../img/avarta/man.jpg";

function Avatar({profileImage}) {
  return (
    <img
      src={profileImage}
      className="border rounded-circle me-3 "
      height="40px"
      width="40px"
    />
  );
}

export default Avatar;
