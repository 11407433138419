import React from 'react'

function SaveButton({onClick, save}) {
  return (
    <button
      className={`btn btn-${save?.id ? 'secondary': 'outline-secondary'} fw-light px-2 px-sm-4 d-lg-flex align-items-lg-center px-lg-3 shadow-none`}
      onClick = {onClick}
    >
      <i className={`far fa-heart me-2 fs-5`} />
      บันทึก
    </button>
  )
}

export default SaveButton