import { PlanContextProvider } from "../contexts/PlanContextProvider";
import SearchResult from "../pages/SearchResult";
import PlanDetails from "../pages/PlanDetails";
import AddPlan from "../pages/AddPlan";
import SavedPlans from "../pages/SavedPlans";
import MyPlans from "../pages/MyPlans";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Home from "../pages/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./Layout";
import PlacesAutocomplete from "../pages/Test";
import Test3 from "../pages/Test3";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContextProvider";

function PrivateRoutes() {
  const user = useContext(AuthContext);

  return (
    <div>
      <Routes>
        {user ? (
          <>
            <Route path="/" element={<Layout />}>
              {/* <Route path="test" element={<PlacesAutocomplete />} />
              <Route path="test2" element={<Test3 />} /> */}
              <Route path="home" element={<Home />} />
              <Route path="search-result" element={<SearchResult />} />
              <Route path="plan-details/:planId" element={<PlanDetails />} />
              <Route path="add-plans" element={<AddPlan />} />
              <Route path="add-plans/:planId" element={<AddPlan />} />
              <Route path="saved-plans" element={<SavedPlans />} />
              <Route path="my-plans" element={<MyPlans />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route index element={<Navigate to="/home" />} />
            </Route>
            <Route path="*" element={<Navigate to="/home" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Layout />}>
              <Route path="home" element={<Home />} />
              <Route path="search-result" element={<SearchResult />} />
              <Route path="plan-details/:planId" element={<PlanDetails />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Route>
            <Route path="*" element={<Navigate to="/home" />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default PrivateRoutes;
