import React from "react";
import FilterNav from "../common/FilterNav";
import SelectInput from "../common/search-filter-input/SelectInput";
import Input from "../common/search-filter-input/Input";

function SearchResultFilter(props) {
  const { mode, setPopular, setRecent, setAuthor, setRoute } = props;

  let filterInput = (
    <>
      <div className="col-2">ตัวกรอง</div>
      <div className="col-2">
        <SelectInput placeholder="นิยม สูง-ต่ำ" setPopular={setPopular} />
      </div>
      <div className="col-2">
        <SelectInput placeholder="ล่าสุด" setRecent={setRecent} />
      </div>

      <div className="col-3">
        <Input placeholder="ผู้เขียน" setAuthor={setAuthor} />
      </div>
      <div className="col-3">
        <Input placeholder="ชื่อเส้นทาง" setRoute={setRoute} />
      </div>
    </>
  );

  if (mode === "saved-plan" || mode === "my-plan") {
    filterInput = (
      <>
        <div className="col-2">ตัวกรอง</div>
        <div className="col-3">
          <SelectInput placeholder="นิยม สูง-ต่ำ" setPopular={setPopular} />
        </div>
        <div className="col-3">
          <SelectInput placeholder="ล่าสุด" setRecent={setRecent} />
        </div>

        <div className="col-3">
          <Input placeholder="ชื่อเส้นทาง" setRoute={setRoute} />
        </div>
      </>
    );
  }
  return (
    <div className="filter-container">
      <div className="d-none d-lg-flex mb-lg-4 ps-2">
        <form action="">
          <div className="row g-2 d-none d-lg-flex row align-items-center ">
            {filterInput}
          </div>
        </form>
      </div>
      <div>
        <FilterNav />
      </div>
    </div>
  );
}

export default SearchResultFilter;
