import React from "react";
import InfoBlog from "./InfoBlog";

function Accordion({ id, value, day }) {

  // แยกข้อมูลของแต่ละช่วงของวัน เผื่อส่ง props ต่อไปที่ Info blogs
  const morningArray = value.filter((el) => el.timeOfDay === "morning");
  const lunchArray = value.filter((el) => el.timeOfDay === "lunch");
  const eveningArray = value.filter((el) => el.timeOfDay === "evening");

  return (
    <div>
      <div className="accordion  " id={`accordionFlush${id}`}>
        <div className="accordion-item">
          <h2 className="accordion-header" id={`flush-heading${id}`}>
            <button
              className="accordion-button collapsed shadow-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#flush-collapse${id}`}
            >
              <div className="row  align-items-baseline">
                <div className="col-12 ">
                  <h5 className="text-secondary">วันที่ {day}</h5>
                </div>
              </div>
            </button>
          </h2>
          <div
            id={`flush-collapse${id}`}
            class="accordion-collapse collapse show"
            data-bs-parent={`#accordionFlush${id}`}
          >
            <div class="accordion-body px-1 px-lg-4">
              <a
                href="https://bit.ly/3mXc1aj"
                target="_blank"
                className=" d-block text-center pt-2 pb-3 text-lg-end me-lg-2"
              >
                <i class="far fa-map me-3 fs-4"></i>สร้างเส้นทางบน Google Map
              </a>

              <div className="row d-flex ">
                <div className="col-12 col-lg-4 px-lg-0  ">
                  <InfoBlog duration="ช่วงเช้า" places={morningArray} />
                </div>
                <div className="col-12 col-lg-4 px-lg-0">
                  <InfoBlog duration="ช่วงบ่าย" places={lunchArray} />
                </div>
                <div className="col-12 col-lg-4 px-lg-0">
                  <InfoBlog duration="ช่วงเย็น" places={eveningArray} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accordion;
