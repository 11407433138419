import React from "react";

function SelectInput(props) {
  const { setPopular, setRecent } = props;
 
  let options = (
    <>
      <option value="0">ความนิยม</option>
      <option value="1">นิยม สูง-ต่ำ</option>
      <option value="-1">นิยม ต่ำ-สูง</option>
    </>
  );

  if (setRecent) {
    options = (
      <>
        <option value="0">ช่วงเวลา</option>
        <option value="1">ล่าสุด</option>
        <option value="-1">เก่าที่สุด</option>
      </>
    );
  }

  const handleOnChange = (value) => {
    if (setPopular) {
      switch (value) {
        case "0":
          return setPopular(null);
        case "1":
          return setPopular(true);
        case "-1":
          return setPopular(false);
      }
    } else {
      switch (value) {
        case "0":
          return setRecent(null);
        case "1":
          return setRecent(true);
        case "-1":
          return setRecent(false);
      }
    }
  };

  return (
    <select
      onChange={(e) => handleOnChange(e.target.value)}
      name=""
      id=""
      className="form-control fw-light fs-small bg-light-gray shadow-none"
    >
      {options}
    </select>
  );
}

export default SelectInput;
