import React from 'react'

function VoteButton({onClick, vote}) {
  return (
    <button
      className={`btn btn-${vote?.id ? 'danger': 'outline-primary'} fw-light px-2 px-sm-4 d-lg-flex align-items-lg-center px-lg-3 shadow-none`}
      onClick = {onClick}
    >
      <i className={`far fa-thumbs-up me-2 fs-5`} />
      โหวต
    </button>
  )
}

export default VoteButton