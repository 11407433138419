import LoginHeader from "../components/login-register/LoginHeader";
import LoginInput from "../components/login-register/LoginInput";
import LoginSocial from "../components/login-register/LoginSocial";
import { Link } from "react-router-dom";
import RegisterForm from "../components/login-register/RegisterForm";

function Register() {
  return (
    <div className="login-container d-block rounded-3 mx-auto pt-4 px-4 border shadow-sm">
       <h1 className="text-center fs-3 fw-normal pt-3">สมัครสมาชิก</h1>
      <div className="d-flex justify-content-center">
        <small className="text-center">
          มีบัญชีผู้ใช้งานแล้ว <Link to = "/login" className="text-secondary">เข้าสู่ระบบ</Link>
        </small>
      </div>
   
      <h6 className="mt-4 fw-normal">สมัครสมาชิกด้วยอีเมล</h6>
      <RegisterForm />

     <LoginSocial />
    </div>
  )
}

export default Register