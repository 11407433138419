import React from 'react'
import { Link } from 'react-router-dom'

function LoginHeader() {

  return (
    <>
        <h1 className="text-center fs-3 fw-normal pt-3">เข้าสู่ระบบ</h1>
      <div className="d-flex justify-content-center">
        <small className="text-center">
          ยังไม่มีผู้ใช้งาน <Link to = "/register" className="text-secondary">ลงทะเบียนคลิกที่นี่</Link>
        </small>
      </div>
    </>
  )
}

export default LoginHeader