import React from "react";
import locationImg from "../../img/local.png";
import voteImg from "../../img/vote.png";
import roadtripImg from '../../img/road-trip.png'

function About() {
  return (
    <div className="about-root-container">
      <div className=" row shadow-sm bg-white mx-2 mx-sm-0  text-center text-lg-start rounded-3 ">
        <div className="col-12 col-lg-6  px-5 py-4 pb-lg-5">
          <div>
            <h1>Plan your Trip</h1>
            <h1>
              with <span className="text-secondary">Best Route</span>
            </h1>
          </div>
          <div className="py-4 d-none d-lg-flex">
            <p className="fw-light">
              เทวาธิราช แจ็กพ็อตแรงผลักเมาท์ กับดัก
              ราสเบอร์รีแครกเกอร์มาม่าโพลล์วอลซ์ แฟกซ์แรงใจแทกติค คอรัปชั่น
              พันธกิจ วอฟเฟิลพรีเมียร์ทำงานสเตย์บ๊อกซ์ สเตชั่นราสเบอร์รีแยม
            </p>
          </div>
          <div className="row my-5 my-lg-0">
            <div className="col-5">
              <img src={locationImg} alt="" width="50px" height="50px" />
              <p className="py-3 " style={{ fontSize: "14px" }}>
                วางแผนเที่ยวใน 3 นาที
              </p>
            </div>
            <div className="col">
              <img src={voteImg} alt="" width="50px" height="50px" />
              <p className="py-3  " style={{ fontSize: "14px" }}>
                ด้วยเส้นทางที่ได้รับความนิยม
              </p>
            </div>
          </div>
        </div>
        <div className="d-none d-lg-flex col-lg-6 right-img" ></div>
      </div>
    </div>
  );
}

export default About;
