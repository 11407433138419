import React from 'react'
import logo from '../img/bestroute.png'

function Footer() {
  return (
    <div>
      <div className="footer-container bg-warning mx-0 text-white fs-5 fw-light d-flex flex-column justify-content-lg-center align-items-lg-center p-3 p-lg-0">
          <div className="logo fs-6 pb-4 d-flex align-items-end">
          <img src= {logo} alt="" width= "40px" heigth = "40px"  className='me-2'/>
            <span>Best Route</span>
          </div>
          <div className="menus">
            <ul className=' d-flex flex-column flex-lg-row justify-content-center fs-6'>
              <li className='mx-lg-4'>Product</li>
              <li className='mx-lg-4'>Features</li>
              <li className='mx-lg-4'>Pricing</li>
              <li className='mx-lg-4'>Resources</li>
              <li className='mx-lg-4'>Careers</li>
              <li className='mx-lg-4'>Help</li>
              <li className='mx-lg-4'>Privacy</li>
            </ul>
          </div>
          <div className="right-reserved ">
            <p>@ 2022 Best Route. All rights reserved.</p>
          </div>
      </div>
    </div>
  )
}

export default Footer