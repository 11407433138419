import React, { useContext } from "react";
import { PlanContext } from "../../contexts/PlanContextProvider";

function SearchInput({ onChange, mode, placeholder }) {
 

  return (
    <>
      <input
        type="text"
        placeholder={placeholder}
        className={`form-control my-2 form-control-lg rounded fw-light fs-6 place-input shadow-none ${mode}`}
        onChange={(e) => onChange(e.target.value)}
      />
   
    </>
  );
}

export default SearchInput;
