import React from "react";
import Dropdown from "../../add-day/add-activity/Dropdown";

function HorizontalCardImg(props) {
  const {image, edit} = props
  return (
    <>
      <div className="p-2">
      {edit === 'edit' ? <div className="d-block d-lg-none card-menus"><Dropdown /></div> : ''}
        <img src={image} className="img-fluid rounded" alt="..." style={{height: 220}} />
      </div>
    </>
  );
}

export default HorizontalCardImg;
