import React from "react";
import PlaceMark from "./PlaceMark";

function InfoBlog({ duration, places }) {
  let logo = "coffee";
  if (duration === "ช่วงบ่าย") {
    logo = "sun";
  } else if (duration === "ช่วงเย็น") {
    logo = "moon";
  }


  return (
    <div className="mx-3 mb-3 py-3 px-4 border rounded d-flex flex-column" style={{height: "100%"}}>
      <div className=" align-items-center ">
        <i class={`fas fa-${logo} me-3 fs-4`}></i> {duration}
      </div>
      <div className="mt-3 fw-light">
        {places.map((place) => (
          <PlaceMark key={place.id} place={place} lat = {place.lat} lng = {place.lng}/>
        ))}
        
      </div>
    </div>
  );
}

export default InfoBlog;
