import React from 'react'
import FascinatingPlaceDesktop from '../home/FascinatingPlaceDesktop'

function FascinatingContainer() {
  return (
    <div className="card-place-container row">
      <div className="col-12 col-lg-3">
        <FascinatingPlaceDesktop />
      </div>
      <div className="col-3 d-none d-lg-flex">
      <FascinatingPlaceDesktop />
      </div>
      <div className="col-3 d-none d-lg-flex">
      <FascinatingPlaceDesktop />
      </div>
      <div className="col-3 d-none d-lg-flex">
      <FascinatingPlaceDesktop />
      </div>
    </div>
  )
}

export default FascinatingContainer