import React from 'react';
import googleLogo from "../../img/logo/google.png"

function LoginSocial() {
  return (
    <>
         <h6 className="mt-4 fw-normal">เข้าสู่ระบบด้วยช่องทางอื่น</h6>
      <div className="d-grid gap-3 my-3 ">
        <button className="btn btn-google fw-light shadow-none text-start d-flex align-items-center">
          <img src={googleLogo} width="20px" className="me-4" />
          Sign in With Google
        </button>
        <button className="btn btn-facebook fw-light text-white shadow-none text-start d-flex align-items-center">
          <i className="fab fa-facebook me-4 fs-4" />
          Sign in With Facebook
        </button>
        <button className="btn btn-line fw-light text-white shadow-none text-start d-flex align-items-center">
          {" "}
          <i className="fab fa-line me-4 fs-4" />
          Sign in With Line
        </button>
      </div>
    </>
  )
}

export default LoginSocial