import SearchResult from "./SearchResult";
import SearchResultFilter from "../components/search-result/SearchResultFilter";
import HorizontalCard from "../components/common/HorizontalCard";
import Pagination from "../components/common/Pagination";
import { getMySavedPlans } from "../api/plan";
import { AddPlanContext } from "../contexts/AddPlanContextProvider";
import { useEffect, useContext, useState } from "react";
import filterSearch from "../function/filterSearch";

function SavedPlans() {
  const { savedPlans, setSavedPlans } = useContext(AddPlanContext);

  useEffect(() => {
    const getSavedPlans = async () => {
      const res = await getMySavedPlans();
      const mySavedPlans = res.data.plans;
      setSavedPlans(mySavedPlans);
    };

    getSavedPlans();
  }, []);

  const [popular, setPopular] = useState(null);
  const [recent, setRecent] = useState(null);
  const [author, setAuthor] = useState("");
  const [route, setRoute] = useState("");

  const [page, setPage] = useState(1);

  const pageLimit = 10;

  const pageNum = savedPlans.length / pageLimit;

  const limitPages = savedPlans.slice((page - 1) * pageLimit, page * pageLimit);

  let filterPlans = filterSearch(limitPages, popular, recent, author, route);

  // เอาไปมาแสดงหน้า SavePlans โลจิคตาม MyPlans
  return (
    <div className="about-root-container">
      <div className="row shadow-sm bg-white mx-2 my-5 mx-sm-0  rounded-3  px-1 px-lg-3 py-4">
        <h2 className="pb-3 pt-4 ps-3">
          เส้นทางที่ <span className="text-secondary">บันทึกไว้</span>
        </h2>
        <SearchResultFilter
          mode="saved-plan"
          setPopular={setPopular}
          popular={popular}
          setRecent={setRecent}
          recent={recent}
          setAuthor={setAuthor}
          author={author}
          setRoute={setRoute}
          route={route}
        />

        {filterPlans.map((el) => (
          <HorizontalCard
            mode="saved-plan"
            user={el.User}
            planName={el.planName}
            planImage={el.planImage}
            votes={el.votes}
            places={el.Places}
            planId={el.id}
            update={el.updatedAt}
          />
        ))}

        <Pagination page={page} setPage={setPage} pageNum={pageNum} />
      </div>
    </div>
  );
}

export default SavedPlans;
