import React from "react";
import img from "../../img/mockup-img/newyork.jpg";
import Tag from "../common/Tag";
import avarta from "../../img/avarta/man.jpg";
import HorizontalCardImg from "./horizontal-card/HorizontalCardImg";
import HorizontalCardHeader from "./horizontal-card/HorizontalCardHeader";
import HorizontalCardProfile from "./horizontal-card/HorizontalCardProfile";
import HorizontalCardLike from "./horizontal-card/HorizontalCardLike";
import Dropdown from "../add-day/add-activity/Dropdown";
import { Link } from "react-router-dom";

function HorizontalCard(props) {
  const {
    edit,
    mode,
    user = {},
    planName,
    planImage,
    votes,
    places = [],
    represent,
    update,
    planId,
  } = props;

  
  // หา
  // เปลี่ยน Array -> SET -> SET -> Array
  // สร้าง array ตัวแทน arealevel2 
  const newAreas = places.map((el) => {
    if (el.areaLevel2 !== null && el.areaLevel2 !== undefined) {
      return el.areaLevel2.trim().toLowerCase();
    }
    return "";
  });

  // หา tag ตัวแทน Area
  // เอาตัวที่เสิชมารวม (เพื่อจะทำให้ตัวที่เสิชเป็นตัวแรกใน tag)
  let addedAreas = [...newAreas];
  if (represent) {
    const newRepresent = represent?.toLowerCase();
    addedAreas = [newRepresent, ...newAreas];
  }
  const allRepresent = Array.from(new Set(addedAreas))
  // หาตัวแทนแค่ 4 ตัว
  const representAreas = allRepresent.slice(0,4);

  const newRepresentAreas = representAreas.map((el) => {
    if (el !== "" && el !== undefined) {
      return el[0].toUpperCase() + el.slice(1, el.length);
    }
    return "";
  });

  // หา tag ตัวแทน Places
  const fullRepresentPlaces = Array.from(new Set(places.map((el) => el.placeName)));
  // ตัดให้เหลือแค่ 15 tag
  const representPlaces = fullRepresentPlaces.slice(0,15)

  let cardContent = (
    <div
      className="card mb-3 mt-3 mt-lg-2 shadow-sm"
      style={{ maxWidth: "100%" }}
    >
      <div className="row g-0">
        <div className="col-md-3">
          <HorizontalCardImg image={planImage} edit={edit} />
        </div>
        <div className="col-md-9">
          <div className="card-body">
            <div className="row ">
              <div className="col-12 col-lg-6 row mx-0 px-0">
                <HorizontalCardHeader dest={planName} update={update} />
                <div className="col-12 mx-0">
                  {newRepresentAreas.map((area, idx) => (
                    <Tag
                      color={idx === 0 ? "bg-secondary" : "bg-warning"}
                      content={area}
                    />
                  ))}
                  {/* <Tag color="bg-warning" content="ลำปาง" />
                    <Tag color="bg-warning" content="เชียงราย" /> */}
                </div>
              </div>
              <div className="col-12 mt-3 mt-lg-0 row d-flex flex-row align-items-center col-lg-6 align-items-lg-center px-2 pe-lg-0 justify-content-lg-end">
                <div
                  className={`col-8 col-lg-6 fs-small fw-light text-lg-end mx-0`}
                >
                  <HorizontalCardProfile avarta={avarta} user={user} />
                </div>
                <div className="col-4 text-end mx-0 ">
                  <HorizontalCardLike like={votes} />
                </div>
                {mode === "my-plan" || mode === "saved-plan" ? (
                  <div className="d-none d-lg-block col-2 text-center">
                    <Dropdown mode={mode} planId={planId} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div>
              <p className="mt-3 mb-1 ps-1">สถานที่ท่องเที่ยว</p>
              <div>
                {representPlaces.map((place) => (
                  <Tag color="badge-outline" content={place} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // LInk ไปยัง PlanDetails  Id ต่างๆ

  let cardMode = <Link to={`/plan-details/${planId}`}>{cardContent}</Link>;

  if (mode === "saved-plan" || mode === "my-plan") {
    cardMode = <span>{cardContent}</span>;
  }
  return cardMode;
}

export default HorizontalCard;
