import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  createPlan,
  getMyPlans,
  getMySavedPlans,
  updatePlan,
} from "../api/plan";

const AddPlanContext = createContext();

function AddPlanContextProvider(props) {
  const [morning, setMorning] = useState([]);
  const [lunch, setLunch] = useState([]);
  const [evening, setEvening] = useState([]);

  // สำหรับอัพเดทและเพิ่ม plan
  const [updateId, setUpdateId] = useState("");
  // setIsUpdate ถูก run มาจากหน้า AddPlan เผื่อเเปลี่ยน state ทำให้ฟังก์ชั่นด้านล่างทำงาน
  const [isUpdate, setIsUpdate] = useState(null);
  const [planName, setPlanName] = useState("");
  const [image, setImage] = useState(null);
  const [isActive, setIsActive] = useState(null);

  // เพิ่ม google place
  const [isInsert, setIsInsert] = useState({
    morning: "",
    lunch: "",
    evening: "",
  });

  // สร้าง Id ของวันแรกจะ uuid
  const [day, setDay] = useState([uuidv4()]);

  const [allPlans, setAllPlans] = useState([]);
  const [savedPlans, setSavedPlans] = useState([]);

  const [loading, setLoading] = useState(false);

  const handleCreateData = async (planName, places, image, isActive) => {
    const res = await createPlan(planName, places, image, isActive);
    console.log(places)
    return res.data.newPlan;
  };

  const navigate = useNavigate();

  // create plan
  useEffect(() => {
    const runCreate = async () => {
      if (isActive !== null) {
        const data = await handleCreateData(
          planName,
          [...morning, ...lunch, ...evening],
          image,
          isActive
        );
        setIsActive(null);
        navigate(`/plan-details/${data.id}`);
      }
    };
    try {
      setLoading(true);
      runCreate();
    } catch (err) {
      setIsActive(null);
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [isActive]);

  // update plan
  useEffect(() => {
    const runUpdate = async () => {
      if (isUpdate !== null) {
        const res = await updatePlan(
          planName,
          [...morning, ...lunch, ...evening],
          image,
          isUpdate,
          updateId
        );
        const newPlan = res.data.newPlan;
        setIsUpdate(null);
        setUpdateId("");
        navigate(`/plan-details/${newPlan.id}`);
      }
    };
    try {
      setLoading(true);
      runUpdate();
    } catch (err) {
      setIsUpdate(null);
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [isUpdate]);

  useEffect(() => {
    // ต้องทำอะไรซักยอ่างให้ morning lunch dinner เปลี่ยนเมื่อมีการ update day
  }, [day]);

  const handleAddDay = (dayId) => {
    const targetIdx = day.findIndex((el) => el === dayId);
    if (targetIdx >= 0) {
      const newDay = [...day];

      newDay.splice(targetIdx + 1, 0, uuidv4());

      setDay(newDay);
    }
  };

  const handleDeleteDay = (dayId) => {
    const targetIdx = day.findIndex((el) => el === dayId);
    if (targetIdx >= 0) {
      const newDay = [...day];

      newDay.splice(targetIdx, 1);
      setDay(newDay);

      const deletedMorning = morning.filter((el) => el.dayId !== dayId);
      const deletedLunch = lunch.filter((el) => el.dayId !== dayId);
      const deletedEvening = evening.filter((el) => el.dayId !== dayId);

      setMorning(deletedMorning);
      setLunch(deletedLunch);
      setEvening(deletedEvening);
    }
  };

  // ลบ google places
  const handleDelete = (timeOfDay, tempId) => {
    switch (timeOfDay) {
      case "morning": {
        const targetIdx = morning.findIndex((el) => el.tempId === tempId);
        if (targetIdx >= 0) {
          const newMorning = [...morning];
          newMorning.splice(targetIdx, 1);
          setMorning(newMorning);
        } else {
          setMorning([...morning]);
        }
        break;
      }
      case "lunch": {
        const targetIdx = lunch.findIndex((el) => el.tempId === tempId);
        if (targetIdx >= 0) {
          const newLunch = [...lunch];
          newLunch.splice(targetIdx, 1);
          setLunch(newLunch);
        }
        break;
      }
      case "evening": {
        const targetIdx = evening.findIndex((el) => el.tempId === tempId);
        if (targetIdx >= 0) {
          const newEvening = [...evening];
          newEvening.splice(targetIdx, 1);
          setEvening(newEvening);
        }
        break;
      }
    }
  };

  // delete plan ของตัวเอง

  const handleDeletePlan = async (planId) => {
    try {
      await axios.delete(`/my-plans/${planId}`);
      const res = await getMyPlans();
      const plans = res.data.plans;
      setAllPlans(plans);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteSaved = async (planId) => {
    try {
      await axios.delete(`/plans/${planId}/save`);
      const res = await getMySavedPlans();
      const plans = res.data.plans;
      setSavedPlans(plans);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AddPlanContext.Provider
      value={{
        morning,
        lunch,
        evening,
        setMorning,
        setLunch,
        setEvening,
        planName,
        setPlanName,
        image,
        setImage,

        isInsert,
        setIsInsert,
        isActive,
        setIsActive,
        isUpdate,
        setIsUpdate,
        setUpdateId,

        handleDelete,

        day,
        setDay,
        handleAddDay,
        handleDeleteDay,

        handleDeletePlan,
        allPlans,
        setAllPlans,
        savedPlans,
        setSavedPlans,
        handleDeleteSaved,

        loading,
      }}
    >
      {props.children}
    </AddPlanContext.Provider>
  );
}

export default AddPlanContextProvider;
export { AddPlanContext };
