import React from 'react'

function CardContainer(props) {
  return (
    <div className="row shadow-sm bg-white mx-2 my-5 mx-sm-0  rounded-3  ps-3 py-4">
        <h2 className="pb-3">{props.header}</h2>
        {props.children}
      </div>
  )
}

export default CardContainer