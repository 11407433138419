import React, { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContextProvider";
import Avatar from "./Avatar";

function HorizontalCardProfile(props) {
  const { fname, lname, profileImage } = props.user;
  
  return (
    <div className="d-flex">
      <Avatar  profileImage = {profileImage}/>
      <div className="d-flex flex-column justify-content-center ">
        {fname} {lname}
      </div>
    </div>
  );
}

export default HorizontalCardProfile;

