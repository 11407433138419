import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import axios from "../config/axios";
import { getSearchPlan } from "../api/plan";

const PlanContext = createContext();

function PlanContextProvider(props) {
  const navigate = useNavigate();

  // props to searchResult page
  const [searchResult, setSearchResult] = useState({});

  //check error
  const [errorMsg, setErrorMsg] = useState({});
  
  //collect search data

  async function searchPlan(place, dayNum) {
    if (place === "") {
      setErrorMsg(
        setErrorMsg((error) => (error.errorPlace = "กรุณาใส่ชื่อสถานที่"))
      );
    }

    if (!validator.isNumeric(dayNum + "")) {
      setErrorMsg((error) => (error.errorDayNum = "กรุณาใส่ตัวเลข"));
    }
    let plans = [];

    if (Object.keys(errorMsg).length <= 0) {
      const res = await getSearchPlan(place, dayNum);
      plans = res.data.searchPlans;
      setSearchResult({ place, plans });
      navigate("/search-result");
    }

    setErrorMsg({});
  }

  return (
    <PlanContext.Provider value={{ searchResult, searchPlan, errorMsg }}>
      {props.children}
    </PlanContext.Provider>
  );
}
export default PlanContextProvider;
export { PlanContext };
