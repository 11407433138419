import React from 'react'
import place from '../../img/mockup-img/newyork.jpg'

function FascinatingPlaceDesktop() {
  return (
    <div>
        <a href="#">
        <div className="card" style={{ minWidth: "90%"}}>
          <img src={place} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title fw-normal">เทวาธิราช แจ็กพ็อต</h5>
            <p className="card-text fw-light fs-small">
              ถ่ายทำซีรีส์ยะเยือกอพาร์ตเมนต์เกสต์เฮาส์ เบิร์นวาฟเฟิลเป่ายิ้งฉุบ
              โหงวเฮ้งนรีแพทย์โรแมนติค อัลบัมคลับ คันยิโอเพ่นซิ่ง
            
            </p>
          </div>
        </div>
      </a>
    </div>
  )
}

export default FascinatingPlaceDesktop