import React from "react";

function HorizontalCardLike(props) {
  return (
    <>
      <i class="fas fa-heart me-2 text-danger fs-4"></i> {props.like}
    </>
  );
}

export default HorizontalCardLike;
