import { useContext, useEffect } from "react";
import AddActivity from "./AddActivity";
import { AddPlanContext } from "../../contexts/AddPlanContextProvider";

function AddDayBlog(props) {
  const addPlan = useContext(AddPlanContext);
  const { day, dayId, targetPlan } = props;
  const {
    morning,
    lunch,
    evening,
    isInsert,
    handleAddDay,
    handleDeleteDay,
    setMorning,
    setLunch,
    setEvening,
  } = addPlan;

  useEffect(() => {
    if (Object.keys(targetPlan).length !== 0) {
      const { Places } = targetPlan;
      const morningPlan = Places.filter((el) => el.timeOfDay === "morning");
      const lunchPlan = Places.filter((el) => el.timeOfDay === "lunch");
      const eveningPlan = Places.filter((el) => el.timeOfDay === "evening");

      setMorning(morningPlan);
      setLunch(lunchPlan);
      setEvening(eveningPlan);
    }
  }, []);

  return (
    <div>
      <div
        className="card my-2 px-3 py-3 mt-5 px-lg-5"
        style={{ maxWidth: "100%" }}
      >
        <div className="text-secondary pt-0 pb-3 fs-4">
          วันที่ <span>{day}</span>
        </div>
        <div className="d-flex align-items-center mt-2 mb-3 border-bottom pb-2">
          <i className={`fas fa-coffee me-3 fs-4`}></i>
          <span className="">ช่วงเช้า</span>
        </div>
        {morning.map((place) =>
          place.dayId === dayId ? (
            place?.tempId !== isInsert?.morning ? (
              <AddActivity
                key={place.tempId}
                isDone={true}
                timeOfDay="morning"
                day={day}
                placeDetails={place}
                tempId={place.tempId}
                dayId={dayId}
              />
            ) : (
              <>
                <AddActivity
                  key={place.tempId}
                  isDone={true}
                  timeOfDay="morning"
                  day={day}
                  placeDetails={place}
                  tempId={place.tempId}
                  dayId={dayId}
                />
                <AddActivity
                  isDone={false}
                  timeOfDay="morning"
                  day={day}
                  dayId={dayId}
                />
              </>
            )
          ) : (
            ""
          )
        )}
        <AddActivity
          isDone={false}
          timeOfDay="morning"
          day={day}
          dayId={dayId}
        />
        <div className="d-flex align-items-center mt-2 mb-3 border-bottom pb-2">
          <i className={`fas fa-sun me-3 fs-3`}></i>
          <span className="">ช่วงบ่าย</span>
        </div>
        {lunch.map((place) =>
          place.dayId === dayId ? (
            place?.tempId !== isInsert?.lunch ? (
              <AddActivity
                key={place.tempId}
                isDone={true}
                timeOfDay="lunch"
                day={day}
                placeDetails={place}
                tempId={place.tempId}
                dayId={dayId}
              />
            ) : (
              <>
                <AddActivity
                  key={place.tempId}
                  isDone={true}
                  timeOfDay="lunch"
                  day={day}
                  placeDetails={place}
                  tempId={place.tempId}
                  dayId={dayId}
                />
                <AddActivity
                  isDone={false}
                  timeOfDay="lunch"
                  day={day}
                  dayId={dayId}
                />
              </>
            )
          ) : (
            ""
          )
        )}
        <AddActivity isDone={false} timeOfDay="lunch" day={day} dayId={dayId} />
        <div className="d-flex align-items-center mt-2 mb-3 border-bottom pb-2">
          <i className={`fas fa-moon me-3 fs-3`}></i>
          <span className="">ช่วงค่ำ</span>
        </div>
        {evening.map((place) =>
          place.dayId === dayId ? (
            place?.tempId !== isInsert?.evening ? (
              <AddActivity
                key={place.tempId}
                isDone={true}
                timeOfDay="evening"
                day={day}
                placeDetails={place}
                tempId={place.tempId}
                dayId={dayId}
              />
            ) : (
              <>
                <AddActivity
                  key={place.tempId}
                  isDone={true}
                  timeOfDay="evening"
                  day={day}
                  placeDetails={place}
                  tempId={place.tempId}
                  dayId={dayId}
                />
                <AddActivity
                  isDone={false}
                  timeOfDay="evening"
                  day={day}
                  dayId={dayId}
                />
              </>
            )
          ) : (
            ""
          )
        )}
        <AddActivity
          isDone={false}
          timeOfDay="evening"
          day={day}
          dayId={dayId}
        />

        <div className="row pt-4 pb-2 justify-content-lg-end">
          <div className="col-6 d-grid col-lg-2">
            <button
              type="button"
              className="btn btn-outline-primary fw-light"
              onClick={() => handleDeleteDay(dayId)}
            >
              ลบ
            </button>
          </div>
          <div className="col-6 d-grid col-lg-2">
            <button
              type="button"
              className="btn btn-primary fw-light"
              onClick={() => handleAddDay(dayId)}
            >
              เพิ่มวันถัดไป
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddDayBlog;
