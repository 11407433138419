import Dropdown from "../add-activity/Dropdown";
import React from "react";

function GooglePlace({ placeDetails, setIsUpdate, tempId, timeOfDay }) {
  const { placeName, lat, lng, googlePlaceId } = placeDetails;

  return (
    <>
      <div className="col-9 col-lg-10  ">
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}&query_place_id=${googlePlaceId}`}
        >
          <p className="my-0">
            <i class="fas fa-map-marker-alt text-danger fs-4 me-3"></i>
            {placeName}
          </p>
        </a>
      </div>
      <div className="row col-3 col-lg-2 text-center d-inline ">
        <Dropdown setIsUpdate={setIsUpdate} tempId={tempId} timeOfDay = {timeOfDay} />
      </div>
    </>
  );
}

export default GooglePlace;
