import React from "react";
import logo from "../img/bestroute.png";
import { Link } from "react-router-dom";
import LoginButton from "../components/login-register/LoginButton";
import Profile from "../components/home/Profile";
import { AuthContext } from "../contexts/AuthContextProvider";
import { useContext } from "react";

function Header() {
  const { user } = useContext(AuthContext);

  return (
    <header>
      <nav className="container navbar fixed-top navbar-expand-lg">
        <div className="container-fluid">
          <Link className="navbar-brand d-flex align-items-end" to="/home">
            <img src={logo} alt="" width="40px" heigth="40px" />
            <span className="ps-2 fs-6 fw-light">Best Route</span>
          </Link>
          <button
            className="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon pt-1">
              <i className="fas fa-bars fs-4"></i>
            </span>
          </button>

          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header ">
              <h5
                className="offcanvas-title fw-normal "
                id="offcanvasNavbarLabel"
              >
                Best Route
              </h5>
              <button
                className="btn-close text-reset shadow-none"
                type="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body align-items-center ">
              <div className=" d-grid mb-4 d-lg-none ">
                <Link
                  to="/login"
                  type="button"
                  className="btn btn-outline-primary "
                >
                  เข้าสู่ระบบ
                </Link>
              </div>
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 fw-light ">
                <li className="nav-item mx-3">
                  <Link to="/home" href="#" className="nav-link active theme-hover">
                    หน้าแรก
                  </Link>
                </li>
                <li className="nav-item mx-3">
                  <Link to="/search-result" className="nav-link active theme-hover">
                    เส้นทาง
                  </Link>
                </li>
                <li className="nav-item mx-3">
                  <a href="#" className="nav-link active theme-hover">
                    สถานที่ท่องเที่ยว
                  </a>
                </li>
              </ul>
              <div className=" d-none d-lg-inline ">
                {user ? (
                  <Profile />
                ) : (
                  <LoginButton
                    style="primary"
                    content="เข้าสู่ระบบ"
                    mode="login"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="menus-container d-none">menus</div>
          <div className="login-button-container d-none">login</div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
