import { AuthContext } from "../../contexts/AuthContextProvider";
import img from "../../img/mockup-img/newyork.jpg";
import { useContext } from "react";
import { Link } from "react-router-dom";

function Profile() {
  const { logout, user } = useContext(AuthContext);

  return (
    <div className="p-2">
      <div className="d-flex  ">
        <span className="dropdown  rounded-2 btn py-0 shadow-none btn-link">
          <span
            className="rounded-2 "
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
          >
            <img
              src= {user.profileImage}
              className=" rounded-circle "
              height="60px"
              width="60px"
            />
          </span>
          <ul class="dropdown-menu dropdown-menu-end mt-2 me-4 ">
            <li>
              <Link to = "/add-plans"  class="dropdown-item fw-light text-center">
                สร้างเส้นทาง
              </Link>
            </li>
            <li>
              <Link to = "/my-plans"  class="dropdown-item fw-light text-center">
                เส้นทางของฉัน
              </Link>
            </li>
            <li>
              <Link to = "/saved-plans"  class="dropdown-item fw-light text-center">
                เส้นทางโปรด
              </Link>
            </li>
            <li>
              <button
                class="dropdown-item fw-light text-center"
                onClick={() => logout()}
              >
                ออกจากระบบ
              </button>
            </li>
          </ul>
        </span>
      </div>
      {/* <img
          src={img}
          className=" rounded-circle "
          
          height = "60px"
          width = "60px"
        /> */}
    </div>
  );
}

export default Profile;
