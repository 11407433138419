import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getDetails,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import useOnclickOutside from "react-cool-onclickoutside";
import "@reach/combobox/styles.css";
import { useEffect, useState, useContext } from "react";
import SaveButton from "../add-activity/SaveButton";
import { AddPlanContext } from "../../../contexts/AddPlanContextProvider";

function Input(props) {
  const { setPlace, placeholder, isUpdate, setIsUpdate, placeDetails } = props;
  const { morning, lunch, evening, isInsert, setIsInsert } =
    useContext(AddPlanContext);

  const [placeName, setPlaceName] = useState("");
  const [areaLevel1, setAreaLevel1] = useState("");
  const [areaLevel2, setAreaLevel2] = useState("");
  const [areaLevel3, setAreaLevel3] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [googlePlaceId, setGooglePlaceId] = useState("");

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const ref = useOnclickOutside(() => {});

  const handleSelect = (val) => {
    // หา googleId จาก dropdown ที่ถูกเลือก
    const matchObj = data.find((obj) => val === obj.description);

    setGooglePlaceId(matchObj.place_id);

    setValue(val, false);
    const parameter = {
      // config สำหรับ get detail
      placeId: matchObj.place_id,
      fields: ["name", "address_components"],
    };

    getDetails(parameter)
      .then((details) => {
        const { address_components, name } = details;
        // หา index ของที่อยู่ย่อยที่มีค่าเป็น country (แต่ละประเทศไม่เหมือนกัน)
        const countryIdx = address_components.findIndex((el) => {
          return el.types[0] === "country";
        });

        setAreaLevel1(address_components[countryIdx]?.long_name);
        setAreaLevel2(address_components[countryIdx - 1]?.long_name);
        setAreaLevel3(address_components[countryIdx - 2]?.long_name);
        setPlaceName(name);
        // console.log("PlaceName", details);
      })
      .catch((error) => {
        console.log(error);
      });

    getGeocode({ address: val }).then((result) => {
      try {
        const { lat, lng } = getLatLng(result[0]);
        setLat(lat);
        setLng(lng);
      } catch (error) {
        console.log(error);
      }
    });

    clearSuggestions();
  };

  // ทำตรงนี้ต่อ เอาstateข้อมูลที่เก็บได้ทั้งหมดส่งไปยัง AddActivities -> state place
  const handleSubmit = () => {
    setPlace({
      placeName,
      googlePlaceId,
      lat,
      lng,
      areaLevel1,
      areaLevel2,
      areaLevel3,
    });

    setValue("");
  };

  return (
    <>
      <div className="col-9 col-lg-10  ">
        <div ref={ref}>
          <Combobox onSelect={handleSelect} aria-labelledby="demo">
            <ComboboxInput
              value={value}
              onChange={handleInput}
              disabled={!ready}
              placeholder={placeholder}
              className="form-control fw-light fs-6 shadow-none"
            />
            <ComboboxPopover>
              <ComboboxList>
                {status === "OK" &&
                  data.map(({ place_id, description }) => (
                    <ComboboxOption key={place_id} value={description} />
                  ))}
              </ComboboxList>
            </ComboboxPopover>
          </Combobox>
        </div>
      </div>
      <div className="row col-3 col-lg-2 text-center d-inline ">
        <button
          className=" rounded-2  fs-3 btn shadow-none btn-link "
          type="button"
          onClick={() => handleSubmit()}
        >
          <i className="far fa-save"></i>
        </button>
      </div>
    </>
  );
}

export default Input;
