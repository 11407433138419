import { AddPlanContext } from "../../../contexts/AddPlanContextProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Dropdown(props) {
  const { mode, isUpdate, setIsUpdate, tempId, timeOfDay, planId } = props;
  const { setIsInsert, handleDelete, handleDeletePlan, handleDeleteSaved } =
    useContext(AddPlanContext);

  const navigate = useNavigate();

  // อันแรกสำหรับตอน Add google autocomplete -> function update และ insert มาจาก props หน้า AddActivity
  let menus = (
    <>
      <li>
        <button
          class="dropdown-item fw-light text-center"
          onClick={() => setIsInsert({ [timeOfDay]: tempId })}
        >
          เพิ่ม
        </button>
      </li>
      <li>
        <button
          class="dropdown-item fw-light text-center"
          onClick={() => setIsUpdate(!isUpdate)}
        >
          แก้ไข
        </button>
      </li>
      <li>
        <button
          class="dropdown-item fw-light text-center"
          onClick={() => handleDelete(timeOfDay, tempId)}
        >
          ลบ
        </button>
      </li>
    </>
  );

  switch (mode) {
    case "saved-plan":
      menus = (
        <>
          <Link to={`/plan-details/${planId}`}>
            <button class="dropdown-item fw-light text-center">
              รายละเอียด
            </button>
          </Link>
          <li>
            <button
              class="dropdown-item fw-light text-center"
              onClick={() => handleDeleteSaved(planId)}
            >
              ลบ
            </button>
          </li>
        </>
      );
      break;
    case "my-plan":
      menus = (
        <>
         <Link to={`/plan-details/${planId}`}>
            <button
              class="dropdown-item fw-light text-center"
              
            >
              รายละเอียด
            </button>
          </Link>
          <li>
            <button
              class="dropdown-item fw-light text-center"
              onClick={() => navigate("/add-plans")}
            >
              สร้าง
            </button>
          </li>
          <li>
            <button
              class="dropdown-item fw-light text-center"
              onClick={() => navigate(`/add-plans/${planId}`)}
            >
              แก้ไข
            </button>
          </li>
          <li>
            <button
              class="dropdown-item fw-light text-center"
              onClick={() => handleDeletePlan(planId)}
            >
              ลบ
            </button>
          </li>
        </>
      );
  }

  return (
    <span className="dropdown  rounded-2 btn py-0 shadow-none btn-link">
      <span
        className="rounded-2 "
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
      >
        <i className="fas fa-ellipsis-h fs-5 text-gray"></i>
      </span>
      <ul class="dropdown-menu dropdown-menu-end mt-2 me-4 ">{menus}</ul>
    </span>
  );
}

export default Dropdown;
