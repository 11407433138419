import React from 'react'

function Tag({color, content}) {
  return (
    <span className={`badge fw-light border-0 px-3 mx-1  ${color}`}>
        {`${content}`}
    </span>
  )
}

export default Tag