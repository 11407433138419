import LoginForm from "../components/login-register/LoginForm";
import LoginHeader from "../components/login-register/LoginHeader";
import LoginInput from "../components/login-register/LoginInput";
import LoginSocial from "../components/login-register/LoginSocial";

function Login() {
  return (
    <div className="login-container d-block rounded-3 mx-auto pt-4 px-4 border shadow-sm">
      <LoginHeader
        
      />
      <h6 className="mt-4 fw-normal">เข้าสู่ระบบด้วยอีเมล</h6>
      <LoginForm />

      <LoginSocial />
    </div>
  );
}

export default Login;
