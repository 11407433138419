import React from 'react'

function LoginInput(props) {
    const {content, placeholder} = props
  return (
    <>
        <label htmlFor="">{content}</label>
          <input
            type="text "
            placeholder= {placeholder ? placeholder : ''}
            className="form-control mt-1 fw-light shadow-none"
          />
    </>
  )
}

export default LoginInput