import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { PlanContext } from "../../contexts/PlanContextProvider";
import SearchForm from "./SearchForm";

function Search() {
  

  return (
    <div>
      <div className="mx-1 mx-lg-5 search-box ">
        <SearchForm />
      </div>
    </div>
  );
}

export default Search;
