import React from "react";

function ShareButton() {
  return (
    <button
      className={`btn btn-primary fw-light px-2 px-sm-4 d-lg-flex align-items-lg-center px-lg-3 shadow-none`}
    >
      <i className={`fas fa-share me-2 fs-5`} />
     แชร์
    </button>
  );
}

export default ShareButton;
