import React, { useEffect, useState, useContext } from "react";
import { AddPlanContext } from "../../contexts/AddPlanContextProvider";
import GooglePlace from "./add-activity/GooglePlace";
import Input from "./add-activity/Input";
import { v4 as uuidv4 } from "uuid";

function AddActivity({ isDone, timeOfDay, day, placeDetails, tempId, dayId }) {
  const [place, setPlace] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  // ผิดตรงที่ placeIdx ใน place จะเป็น undefined ตั้งแต่ตอนเก็บข้อมูล

  const {
    morning,
    lunch,
    evening,
    setMorning,
    setLunch,
    setEvening,
    isInsert,
    setIsInsert,
 
  } = useContext(AddPlanContext);

  //เราค่าจาก input ไปเก็บที่ context
  useEffect(() => {
    if (Object.keys(place).length !== 0) {
     
      if (
        !isUpdate &&
        !isInsert.morning &&
        !isInsert.lunch &&
        !isInsert.evening
      ) {
        // การเพิ่มหมุด google auto complete
        switch (timeOfDay) {
          case "morning": {
            setMorning([
              ...morning,
              { ...place, timeOfDay, day, tempId: uuidv4(), dayId },
            ]);
            break;
          }
          case "lunch": {
            setLunch([
              ...lunch,
              { ...place, timeOfDay, day, tempId: uuidv4(), dayId },
            ]);
            break;
          }
          case "evening": {
            setEvening([
              ...evening,
              { ...place, timeOfDay, day, tempId: uuidv4(), dayId },
            ]);
            break;
          }
        }
        
      } else if (isInsert.morning || isInsert.lunch || isInsert.evening) {
        // สำหรับ insert ในแต่ละหมุด isInsert มาจาก context
        switch (timeOfDay) {
          case "morning": {
            const targetIdx = morning.findIndex(
              (el) => el.tempId === isInsert.morning
            );
            if (targetIdx >= 0) {
              const newMorning = [...morning];
              newMorning.splice(targetIdx + 1, 0, {
                ...place,
                timeOfDay,
                day,
                tempId: uuidv4(),
                dayId,
              });
              setMorning(newMorning);
            }
            break;
          }
          case "lunch": {
            const targetIdx = lunch.findIndex(
              (el) => el.tempId === isInsert.lunch
            );
            if (targetIdx >= 0) {
              const newLunch = [...lunch];
              newLunch.splice(targetIdx + 1, 0, {
                ...place,
                timeOfDay,
                day,
                tempId: uuidv4(),
                dayId,
              });
              setLunch(newLunch);
            }
            break;
          }
          case "evening": {
            const targetIdx = evening.findIndex(
              (el) => el.tempId === isInsert.evening
            );
            if (targetIdx >= 0) {
              const newEvening = [...evening];
              newEvening.splice(targetIdx + 1, 0, {
                ...place,
                timeOfDay,
                day,
                tempId: uuidv4(),
                dayId,
              });
              setEvening(newEvening);
            }
            break;
          }
        }
      } else {
        // สำหรับ update google place แต่ละหมุด isUpdate มาจากหน้านี้
        switch (timeOfDay) {
          case "morning": {
            const targetIdx = morning.findIndex((el) => el.tempId === tempId);
            if (targetIdx >= 0) {
              const newMorning = [...morning];
              newMorning.splice(targetIdx, 1, {
                ...place,
                timeOfDay,
                day,
                tempId,
                dayId,
              });
              setMorning(newMorning);
            }
            break;
          }
          case "lunch": {
            const targetIdx = lunch.findIndex((el) => el.tempId === tempId);
            if (targetIdx >= 0) {
              const newLunch = [...lunch];
              newLunch.splice(targetIdx, 1, {
                ...place,
                timeOfDay,
                day,
                tempId,
                dayId,
              });
              setLunch(newLunch);
            }
            break;
          }
          case "evening": {
            const targetIdx = evening.findIndex((el) => el.tempId === tempId);
            if (targetIdx >= 0) {
              const newEvening = [...evening];
              newEvening.splice(targetIdx, 1, {
                ...place,
                timeOfDay,
                day,
                tempId,
                dayId,
              });
              setEvening(newEvening);
            }
            break;
          }
        }
      }
    }
  }, [place]);

  // useEffect(() => {
  //   switch (timeOfDay) {
  //     case "morning": {
  //       const targetIdx = morning.findIndex((el) => el.tempId === tempId);
  //       if (targetIdx >= 0) {
  //         const newMorning = [...morning];
  //         newMorning.splice(targetIdx, 1);
  //         setMorning(newMorning);
  //       }
  //       break;
  //     }
  //     case "lunch": {
  //       const targetIdx = lunch.findIndex((el) => el.tempId === tempId);
  //       if (targetIdx >= 0) {
  //         const newLunch = [...lunch];
  //         newLunch.splice(targetIdx, 1);
  //         setLunch(newLunch);
  //       }
  //       break;
  //     }
  //     case "evening": {
  //       const targetIdx = evening.findIndex((el) => el.tempId === tempId);
  //       if (targetIdx >= 0) {
  //         const newEvening = [...evening];
  //         newEvening.splice(targetIdx, 1);
  //         setEvening(newEvening);
  //       }
  //       break;
  //     }
  //   }
  // }, [isDelete]);

  useEffect(() => {
    // console.log('track timeOfDay')
    if (isUpdate) {
      setIsUpdate(false);
    }
    // ต้องเช็คด้วยว่าครั้งแรกเป็น place มีหรือยัง
    if (
      Object.keys(place).length !== 0 &&
      (isInsert.morning || isInsert.lunch || isInsert.evening)
    ) {
      setIsInsert({
        morning: "",
        lunch: "",
        evening: "",
      });
    }
    setPlace({});
  }, [morning, lunch, evening]);

  let input = (
    // เวลากดเพิ่ม หรือกด update หมุด google auto complete
    <Input
      setPlace={setPlace}
      placeholder="ใส่ชื่อสถานที่"
      placeDetails={placeDetails}
      setIsUpdate={setIsUpdate}
      isUpdate={isUpdate}
    />
  );

  if (isUpdate ? false : isDone) {
    input = (
      <GooglePlace
        placeDetails={placeDetails}
        setIsUpdate={setIsUpdate}
        isUpdate={isUpdate}
        tempId={tempId}
        timeOfDay={timeOfDay}
      />
    );
  }

  return (
    <div>
      <div className="row my-3 mb-lg-2 d-lg-flex align-items-center align-items-lg-baseline add-activity">
        {input}
      </div>
    </div>
  );
}

export default AddActivity;
