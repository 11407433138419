import React from "react";

function Input(props) {
  const { setAuthor, setRoute, placeholder } = props;

  return (
    <input
      type="text"
      className="form-control fw-light fs-small bg-light-gray shadow-none"
      placeholder={placeholder}
      onChange={
        setAuthor
          ? (e) => setAuthor(e.target.value)
          : (e) => setRoute(e.target.value)
      }
    />
  );
}

export default Input;
