import React from "react";

function Pagination({ page, setPage, pageNum }) {
  let paginationNum = [];

  for (let i = 0; i < Math.ceil(pageNum); i++) {
    paginationNum.push(
      <li className={`page-item ${page === i + 1 && "active"}`}>
        <a className="page-link" onClick={() => setPage(i + 1)}>
          {i + 1}
        </a>
      </li>
    );
  }

  const nextPage = page + 1
  const prevPage = page - 1

  return (
    <div className="d-flex justify-content-end mt-3 mt-lg-4">
      <nav aria-label="... ">
        <ul className="pagination">
          <li className={`page-item ${page === 1 && 'disabled'}`}>
            <a className="page-link" onClick={() => setPage(prevPage)}>
              Previous
            </a>
          </li>

          {paginationNum}

          <li className={`page-item ${page === Math.ceil(pageNum) && 'disabled'}`}>
            <a className="page-link" onClick={() => setPage(nextPage)}>
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
